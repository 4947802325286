
export default {
    name: 'WalletExplain',
    components: {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        initialSet() {
            this.list = [{
                title: `1.What's My Wallet?`,
                content: `lt is a kind of more convenient payment method which customers can store money beforehand and then make the purchase directly.`,
            }, {
                title: `2.How to use my wallet?`,
                content: `The wallet balance will show up at the payment page and then you can choose to pay for your order`,
            }, {
                title: `3.How can I get wallet balance?`,
                content: `Invite friends to make purchase and you can get balance rewards; Other rewards activites held on YFN or our Social network like Instagram;`,
            }, {
                title: `4.How to withdraw my deposit from my wallet?`,
                content: `Click the submit button under your balance amount and fill the amount of money you want to withdraw.`,
            }, {
                title: `5.When will l get my money after withdraw deposit?`,
                content: `Usually it takes about 7-14 working days for the refund process to be done. Just check your account later for the updates.`,
            }, {
                title: `6. Can I transfer my wallet to another YFN account?`,
                content: `For financial security problem, it is better to keep the wallet balance in your own account.`,
            }, {
                title: `7. Why can't l withdraw my deposit?`,
                content: `Please make sure the amount you have filled in does not exceed the total amount.`,
            }]
        }
    },
}
