
export default {
    name: 'Tripartite-EChat',
    components: {},
    props: {},
    data() {
        return {
            // hasAddCustom: true,
            bodyClass: '',
            userId: '',
            deviceId: '',
            country: {},
            lang: {},
            myData: {},
            metaData: {},
            script: [],
            params: {}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setHead() {
            this.getPage()
            this.getSto()
            this.setScript()
            const bodyAttrs = {
                class: this.bodyClass
            };
            return {
                bodyAttrs,
                script: this.script
            }
        },
        // 插入页面
        getPage() {
            const route = this.$route;
            const path = [
                'shoppingbag',
                'cardpay',
                'address',
                'addLocation',
                'policy',
                'activity',
                'category',
                'post',
                'customer',
                'track',
                'influencer',
                '-pwd',
                'login',
                'coupon',
                'comment',
                '404',
                'review-',
                'contact-us',
                'item',
                'orderconfirm',
                'mine',
                'jewelry-exchange'
            ];
            this.bodyClass = 'echat-show'
            path.some(p => {
                if(route.path.includes(p)) {
                    this.bodyClass = ''
                    return true
                }
            })
        },
        // 获取缓存
        getSto() {
            const countryStore = this.$store.state.national.country
            const languageStore = this.$store.state.national.language
            this.country = this.$storage.get('country') || countryStore || {}
            this.lang = this.$storage.get('language') || languageStore || {}
            this.userId = this.$storage.get('userId')
            this.deviceId = this.$storage.get('deviceId')
        },
        // 设置echat会员自定义参数
        setMyData() {
            this.myData = {
                yfnUserId: this.userId,
                yfnDeviceId: this.deviceId,
                yfnCountryCode: this.country.countryCode,
                yfnLanguage: this.lang.langCode
            }
        },
        // 设置echat会员参数metaData
        setMetaData() {
            this.params.uid = this.userId || this.deviceId
            this.params.vip = 1
            this.params.grade = 1
            this.params.category = 'Ordinary member'
            this.params.nation = this.country.englishName
            let metaDataXml = '', paramsTag = '';
            Object.keys(this.params).forEach(key => {
                paramsTag += `<${key}>${this.params[key]}</${key}>`
            })
            metaDataXml = `<xml>${paramsTag}</xml>`
            this.metaData = WECHAT.wxEncrypt(metaDataXml, 'YjdmODBkZTFiYmM1NDYzNmEyNGEwYTA2M2UwNzYyOTU', 'D17C4D6D70E9B7A41A38DD921FBFA42E').toString()
        },
        // script
        setScript() {
            const script = [{
                vmid: 'WECHAT',
                src: 'https://cdn2.selleroa.com/aes.js',
                async: true,
                defer: true,
                body: true,
                callback: () => {
                    this.setMetaData()
                    this.setMyData()
                }
            }, {
                innerHTML: `window._echat=window._echat||function(){(_echat.q=_echat.q||[]).push(arguments)};_echat.l=+new Date;
                            _echat('initParam', { companyId: 531938, echatTag: 'shopify', lan: '${this.lang.langCode}', metaData: '${this.metaData}', myData: '${this.myData}'});`,
                type: 'text/javascript',
                body: true
            }, {
                src: 'https://frs.echatsoft.com/visitor/echat.js',
                async: true,
                defer: true,
                body: true,
                id: 'echatmodulejs',
                charset: 'UTF-8'
            }];
            this.script = script
        }
    },
}
