
export default {
    name: 'TrackSearchForm',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        middleEmit: Object,
        search: Object,
    },
    data() {
        return {
            params: {
                orderId: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        inputTrack(e) {
            this.search.hasTip = !e
            this.$set(this.search, 'hasTip', !e)
            this.$set(this.search, 'msg', '')
        },
        handleConfirm() {
            if(!this.params.orderId) {
                this.search.hasTip = true
            }
            this.middleEmit.fn('handleConfirm', this.params)
        },
        // 初始化
        initialSet() {
            this.params.orderId = this.query.order_id
            let hasVisible = true;
            if(this.query.from) {
                hasVisible = !['orderdetail', 'orderDetail'].includes(this.query.from)
            }
            this.$set(this.search, 'hasVisible', hasVisible)
        },
    },
}
