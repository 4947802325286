
export default {
    name: 'WalletHistory',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            history: {
                visible: true,
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getWalletUse()
        this.loadMore()
    },
    methods: {
        // 获取钱包使用情况
        getWalletUse() {
            this.$api.wallet.getWalletUseList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum
            }).then(response => {
                const res = response.result || {};
                this.history.list = res.list || []
                this.history.visible = !!this.history.list.length
                this.page.totalPage = res.totalPage
                this.setData()
            })
        },
        setData() {
            this.history.list.forEach(p => {
                p.rsDesc = ''
                if(p.typeDesc == 'System Send') {
                    p.rsDesc = `${this.$translate('Valid before')} ${this.$dayjs(p.expireDate).format('MM/DD/YYYY HH:mm')}`
                }
                if(['Refund', 'Order Pay'].includes(p.typeDesc)) {
                    p.typeDesc = `ID: ${p.orderId}`
                }
            })
        },
        // 触底加载
        loadMore() {
            this.$fnApi.pullRefresh.load(e => {
                if(e.isIntersecting && this.page.totalPage > this.page.pageNum) {
                    this.page.pageNum += 1
                    this.getWalletUse()
                }
            }, {
                target: '.WalletHistory--absolve'
            })
        }
    }
}
