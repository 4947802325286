
import Vue from 'vue'
import { useWindowScroll } from '@vueuse/core'
const { y } = useWindowScroll();

export default {
    name: 'Aside',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            deviceId: '',
            y: y,
            innerHeight: 2000,
            top: {
                hasTop: false,
            },
            bag: {
                visible: false,
                quantity: ''
            }
        }
    },
    head() {
        this.initialSet()
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.bag.visible && this.deviceId && this.getBagQuantityAjax()
    },
    methods: {
        // 获取购物车
        getBagQuantityAjax() {
            this.$api.bag.getUserBagQuantity().then(response => {
                const res = response.result || {};
                this.bag.quantity = res.count || ''
            })
        },
        handleTop() {
            this.$VueScrollTo.scrollTo('body')
        },
        handleBag() {
            this.$router.push({
                name: 'Bag'
            })
        },
        initialSet() {
            const route = this.$route || {};
            this.hasLogin = this.$login()
            this.innerHeight = window.innerHeight * 2
            this.deviceId = this.$storage.get('deviceId')
            // 加购
            const bagPath = [
                'Search',
                'MemberBirthday',
                'Product-Seo'];
            this.bag.visible = false
            if(bagPath.includes(route.name)) {
                this.bag.visible = true
            }
            // 置顶
            const topPath = ['Bag','Product-Seo'];
            this.top.hasTop = true
            if(topPath.includes(route.name)) {
                this.top.hasTop = false
            }
            Vue.prototype.$updateAsideBagQuantity = this.getBagQuantityAjax
        }
    },
}
