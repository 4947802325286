
export default {
    name: 'WalletBalance',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            balance: {
                visible: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getWalletList()
    },
    methods: {
        // 获取钱包列表
        getWalletList() {
            this.$api.wallet.getWalletList().then(response => {
                const res = response.result || {};
                this.balance.list = res || []
                this.balance.visible = !!this.balance.list.length
            })
        },
        handleBack() {
            this.$router.back()
        },
        handleTip() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '1687860357743cCwhTwH3'
                }
            })
        }
    }
}
