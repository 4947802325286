
export default {
    name: 'TrackSearchLog',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        fnApi: Object,
        middleEmit: Object,
        package: Object,
        info: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleCopy() {
            this.fnApi.copy.initial(this.package.target.trackingNumber)
            this.$Toast(this.$translate('Copied'))
        },
    },
}
