
export default {
    name: 'Business',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            biz: {
                openTest: false,
                testNum: 0,
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        // const referrer = this.$store.state.biz.referrer;
        // if(process.client && ['Login'].includes(referrer)) this.$router.go(0)
    },
    mounted() {
        this.initialSet()
        this.setInitialAccess()
        window.setOpenTest = this.setOpenTest
    },
    methods: {
        // 首次用户访问
        setInitialAccess() {
            const initialAccess = sessionStorage.getItem('biz/initialAccess')
            if(!initialAccess) {
                const time = new Date().getTime()
                this.$ctxApi.spm.initial({
                    c: 102,
                    d: 1,
                    g: {
                        startTime: time
                    }
                }, this)
                sessionStorage.setItem('biz/initialAccess', time)
            }
        },
        // 开启测试
        setOpenTest() {
            const openTest = this.$storage.get('biz/openTest')
            if(!openTest) {
                this.$storage.set('biz/openTest', true)
            }
            this.biz.openTest = true
        },
        handleTest() {
            this.biz.testNum += 1
            if(this.biz.testNum < 2) {
                let clear = setTimeout(() => {
                    this.biz.testNum = 0
                    clearTimeout(clear)
                }, 300)
            }
            if(this.biz.testNum > 1) {
                this.$storage.remove('biz/openTest')
                this.biz.openTest = false
                this.biz.testNum = 0
            }
        },
        // 初始化
        initialSet() {
            const openTest = this.$storage.get('biz/openTest')
            this.biz.openTest = openTest
        }
    },
}
