
export default {
    name: 'TrackSearchProduct',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        info: Object,
        middleEmit: Object,
    },
    data() {
        return {
            tip: `To fulfill the order faster, we'll pack your items
                in several packages. Your understanding will be really appreciated`,
            package: {
                list: [], // 包裹list
                orderItem: [], // 当前包裹下订单商品
                target: {}, // 当前包裹信息
                type: 1, // 当前包裹索引
                length: 0,
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            let packageList = this.info.packageList;
            this.package.list = packageList || []
            this.package.length = this.package.list.length
            if(!this.package.length) {
                return
            }
            this.setType()
            this.setTarget()
        },
        setType() {
            this.package.list.forEach((item, i) => {
                if(item.id == this.query.package_id) {
                    this.package.type = i + 1
                }
            })
        },
        setTarget() {
            let target = {};
            target = this.package.list[this.package.type - 1]
            target.orderItemIdList = target.orderItemId.split(',')
            target.orderItemLength = target.orderItemIdList.length
            target.targetTrackInfo = target.trackInfo[0]
            this.package.target = target
            this.package.orderItem = this.info.yfnOrderItem.filter(item => {
                return target.orderItemId.includes(item.id)
            }) || []
            this.setStatus()
            this.setTrack()
            this.middleEmit.fn('setPackage', this.package)
        },
        setStatus() { // 物流状态
            let target = this.package.target;
            let r = this.middleEmit.fn('setStatus', this.package.target.status);
            target.statusType = r.type
            target.statusText = r.text
        },
        setTrackIcon(val) { // 物流进度图标
            let arr = [{
                text: 'OrderPlaced',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1687745696890.png'
            }, {
                text: 'InfoReceived',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1687745720450.png'
            }, {
                text: 'Transit',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1687745731988.png'
            }, {
                text: 'Pickup',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1687745746231.png'
            }, {
                text: 'Delivered',
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1687745758049.png'
            }]
            let r = arr.filter(item => {
                return item.text.toLocaleLowerCase() == val.toLocaleLowerCase()
            })
            return r[0] || {icon: ''}
        },
        setTrack() {
            // 送达时间
            let target = this.package.target;
            let time = target.deliveryTimeInfo;
            if(time) {
                target.deliveryStartStr = `${this.$translate(this.$dayjs(time.deliveryStartTime).format('MMM'))} ${this.$dayjs(time.deliveryStartTime).format('DD')}`
                target.deliveryEndStr = `${this.$translate(this.$dayjs(time.deliveryEndTime).format('MMM'))} ${this.$dayjs(time.deliveryEndTime).format('DD')}`
                target.deliveryStr = `${this.$translate('About')} ${target.deliveryStartStr} - ${target.deliveryEndStr}`
                if(!['Processing', 'Shipped', 'Pending'].includes(target.status)){
                    target.deliveryStr = ''
                }
            }
            // 物流进度
            target.trackStep = []
            let trackInfo = target.trackInfo || [];
            trackInfo.forEach((item, i) => {
                let obj = {...item};
                let icon = this.setTrackIcon(item.checkpointDeliveryStatus.replace(/\s*/g,"")).icon;
                let next = trackInfo[i +1] || {};
                obj.icon = ''
                obj.statusText = ''
                if(item.checkpointDeliveryStatus != next.checkpointDeliveryStatus) {
                    let deliveryStatus =  item.checkpointDeliveryStatus;
                    obj.statusText = deliveryStatus[0].toUpperCase() + deliveryStatus.substr(1)
                    obj.icon = icon
                }
                // 物流时间
                let timeStr = `${this.$dayjs(item.checkpointDate).format('HH:mm')},${this.$translate(this.$dayjs(item.checkpointDate).format('MMM'))} ${this.$dayjs(item.checkpointDate).format('DD')}`;
                obj.timeStr = timeStr
                target.trackStep.push(obj)
            })
        },
        // tab标签
        handleTag(obj) {
            let i = obj.i + 1;
            if(this.package.type == i) {
                return
            }
            this.package.type = i
            this.setTarget()
        },
    },
}
