
export default {
    name: 'Tripartite-Common',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            script: {},
            headAttrs: {
                script: []
            }
        }
    },
    head() {
        this.setHead()
        return this.headAttrs
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setShaScript() {
            // sha256
            this.script.sha = [{
                async: true,
                type: 'text/javascript',
                src: 'https://cdn2.selleroa.com/yfn/sha256.js'
            }]
        },
        setArmsScript() {
            // 阿里云检测埋点
            this.script.arms = [{
                innerHTML: `!(function(c,b,d,a){c[a]||(c[a]={});c[a].config={pid:"ea4glq2myj@ea8a708a31bd273",appType:"web",imgUrl:"https://retcode-us-west-1.arms.aliyuncs.com/r.png?",sendResource:true,enableLinkTrace:true,behavior:true};
                    with(b)with(body)with(insertBefore(createElement("script"),firstChild))setAttribute("crossorigin","", src=d, async=true, defer=true)
                    })(window,document,"https://retcode.alicdn.com/retcode/bl.js","__bl");`
            }]
        },
        setHead() {
            // const hasAddTri = this.$store.state.biz.hasAddTri;
            this.setShaScript()
            // if(!hasAddTri) {
            //     this.setArmsScript()
            //     this.$store.commit('biz/setAddTri', true)
            // }
            this.headAttrs.script = []
            Object.keys(this.script).forEach((key, v) => {
                this.headAttrs.script.push(...this.script[key])
            })
        }
    },
}
