
export default {
    name: 'SEO',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            lang: {},
            meta: {
                otherMeta: [],
                merge: []
            },
            link: [],
            htmlAttrs: {},
            headAttrs: {},
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setHead() {
            this.mergeHead()
            return this.headAttrs
        },
        // 语言
        setLang() {
            const storeLang = this.$store.state.national.language ||  {};
            this.lang = this.$storage.get('language') || storeLang;
            this.htmlAttrs.lang = this.lang.langCode || 'en'
        },
        // 阿语适配
        setDir() {
            const dir = ['ar_SA'].includes(this.lang.identify) ? 'rtl' : 'ltr';
            this.htmlAttrs.dir = dir
        },
        // meta
        setOtherMeta() {
            // Safari分享图标和标题
            this.meta.otherMeta = [{
                key: 'name',
                keyVal: 'apple-mobile-web-app-capable',
                content: 'yes'
            }, {
                key: 'name',
                keyVal: 'apple-touch-fullscreen',
                content: 'yes'
            }, {
                key: 'name',
                keyVal: 'apple-mobile-web-app-title',
                content: 'YFN'
            }]
            // robots
            // if(this.$route.name != 'Home') {
            //     this.meta.otherMeta.push({
            //         key: 'name',
            //         keyVal: 'robots',
            //         content: 'noindex'
            //     })
            // }
            // 网站归属验证
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore || {};
            const mRealmName = country.mRealmName || '';
            let verification = {
                key: 'name',
                keyVal: 'google-site-verification',
            };
            if(mRealmName.includes('yfn.uk')) {
                verification.content = 'hvc3zvN4dMIyJToxzvZ84xPq8jw6v_Ruuh3H7xmi34Q'
            }
            if(mRealmName.includes('yfn.fr')) {
                verification.content = '-TH88S38USQl-Rbwc25OvzQOXMCUVsAAG5RPpG8DnwY'
            }
            if(mRealmName.includes('yfn.es')) {
                verification.content = '0Me8rJMK2pvtmKRwRFHyCwdOIhK2P2sXbiqCRk2qY8Y'
            }
            if(mRealmName.includes('yfn.pl')) {
                verification.content = 'C7riwvBX-owJvUpuFisd99IjbebERakEDvd78LAgdUc'
            }
            if(mRealmName.includes('yfn.ae')) {
                verification.content = 'iCDDdjX3L4sI2h1Cv6yBRHsw0RYs99RSwGF3G3JasY8'
            }
            if(mRealmName.includes('yfn.mx')) {
                verification.content = 'McUgR1cu5dpfYCs0lmJsMajbY_UQpH13iYEDDO0a4J8'
            }
            if(mRealmName.includes('yfn.jp')) {
                verification.content = 'LOVRKJMk8gIC7D9H57gcjPlGysxeyFXX-jduiZiEWI4'
            }
            if(mRealmName.includes('yfn.de')) {
                verification.content = 'Fz2A8G-yagOxDhqnuDR2lebYsAvU6mHMEgb0kDt-fcA'
            }
            if(!verification.content) {
                verification.content = '1eQ3PBY7n2VJbg4n3GhifQ0uz77tZO6aNIHNUtezx68'
            }
            this.meta.otherMeta.push(verification)
        },
        setMergeMeta() {
            this.setOtherMeta()
            this.meta.merge = [];
            const metaArr = [...this.meta.otherMeta];
            metaArr.forEach(p => {
                const obj = {
                    hid: p.keyVal,
                    [p.key]: p.keyVal,
                    content: p.content
                };
                this.meta.merge.push(obj)
            })
        },
        // link
        setAltLink() {
            const fullPath = this.$route.path;
            let list = [
                {
                    hreflang: 'en',
                    href: 'https://m.yfn.com'
                }, 
                {
                    hreflang: 'pt-BR',
                    href: 'https://br.yfn.com'
                }, 
                {
                    hreflang: 'en-AU',
                    href: 'https://au.yfn.com'
                }, 
                {
                    hreflang: 'en-CA',
                    href: 'https://ca.yfn.com'
                }, 
                {
                    hreflang: 'fr-FR',
                    href: 'https://m.yfn.fr'
                }, 
                {
                    hreflang: 'de-DE',
                    href: 'https://m.yfn.de'
                }, 
                // {
                //     hreflang: 'ga-IE',
                //     href: 'https://ie.yfn.com'
                // }, 
                {
                    hreflang: 'it-IT',
                    href: 'https://it.yfn.com'
                }, 
                {
                    hreflang: 'es-ES',
                    href: 'https://m.yfn.es'
                }, 
                // {
                //     hreflang: 'en-JP',
                //     href: 'https://m.yfn.jp'
                // }, 
                {
                    hreflang: 'es-MX',
                    href: 'https://m.yfn.mx'
                }, 
                {
                    hreflang: 'en-GB',
                    href: 'https://m.yfn.uk'
                }, 
                {
                    hreflang: 'nl-NL',
                    href: 'https://nl.yfn.com'
                }, 
                // {
                //     hreflang: 'en-NZ',
                //     href: 'https://nz.yfn.com'
                // }, 
                {
                    hreflang: 'pl-PL',
                    href: 'https://m.yfn.pl'
                }, 
                {
                    hreflang: 'pt-PT',
                    href: 'https://pt.yfn.com'
                }, 
                // {
                //     hreflang: 'ar-SA',
                //     href: 'https://sa.yfn.com'
                // }, 
                // {
                //     hreflang: 'en-AE',
                //     href: 'https://m.yfn.ae'
                // }, 
                {
                    href: 'https://m.yfn.com',
                    hreflang: 'x-default'
                }
            ]
            let link = []
            list.forEach(p => {
                link.push({
                    rel: 'alternate',
                    hreflang: p.hreflang,
                    href: `${p.href}${fullPath}`
                })
            })
            return link
        },
        setLink() {
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore;
            const pcRealmName = country.pcRealmName || '';
            const mRealmName = country.mRealmName || '';
            const fullPath = this.$route.path;
            this.link = []
            let page = ''
            if(this.$route?.query?.page>1&&fullPath.indexOf('collection/')!=-1){
                page = `?page=${this.$route?.query?.page}`
                if(this.$route?.query?.page==2){
                    this.link.push({
                        rel: 'prev',
                        href: `${mRealmName}${fullPath}`
                    })
                }else{
                    this.link.push({
                        rel: 'prev',
                        href: `${mRealmName}${fullPath}?page=${Number(this.$route?.query?.page)-1}`
                    })
                }
                
                this.link.push({
                    rel: 'next',
                    href: `${mRealmName}${fullPath}?page=${Number(this.$route?.query?.page)+1}`
                })
            }else if(fullPath.indexOf('collection/')!=-1){
                this.link.push({
                    rel: 'next',
                    href: `${mRealmName}${fullPath}?page=2`
                })
            }
            // google 版面
            this.link.push({
                rel: 'canonical',
                href: `${pcRealmName}${fullPath}${page}`
            })
            // Safari分享图标和标题
            this.link.push({
                rel: 'apple-touch-icon',
                href: 'https://cdn2.selleroa.com/yfn-upload/review/1693287424941.png'
            })
            // alternate
            this.link.push(...this.setAltLink())
        },
        // merge
        mergeHead() {
            this.setLang()
            this.setDir()
            this.setMergeMeta()
            this.setLink()
            this.headAttrs.meta = this.meta.merge
            this.headAttrs.link = this.link
            this.headAttrs.htmlAttrs = this.htmlAttrs
        }
    },
}
