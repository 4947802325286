
import fnApi from '/utils/common/methods'
import TrackSearchHead from './components/TrackSearchHead'
import TrackSearchForm from './components/TrackSearchForm'
import TrackSearchProduct from './components/TrackSearchProduct'
import TrackSearchLog from './components/TrackSearchLog'

export default {
    name: 'TrackSearch',
    components: {
        TrackSearchHead,
        TrackSearchForm,
        TrackSearchProduct,
        TrackSearchLog
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {},
            middleEmit: {},
            fnApi: fnApi,
            hasApp: false,
            hasLogin: false,
            skeleton: false,
            info: {}, // 订单信息
            packageInfo: {}, // 包裹信息
            params: {},
            search: {
                msg: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        if(this.params.orderId) {
            this.getLogAjax()
        } else {
            this.skeleton = false
        }
    },
    methods: {
        // 获取物流数据
        getLogAjax() {
            this.$api.order.setTrackSearch({
                orderNumbers: this.params.orderId
            }).then(response => {
                const res = response.result || {};
                this.info = res
                this.skeleton = false
                if(response.code == 'error') {
                    this.search.msg = this.$translate(`Sorry, we can't find any tracking information, please make sure you are entering the right number`)
                }
            }).catch((err) => {
                this.search.msg = this.$translate(`Sorry, we can't find any tracking information, please make sure you are entering the right number`)
            });

        },
        // 设置包裹数据
        setPackage(obj) {
            this.packageInfo = obj
        },
        // 物流查询
        handleConfirm(obj) {
            this.params.orderId = obj.orderId
            this.info = {}
            let list = this.packageInfo.list || [];
            if(this.query.package_id && list.length) {
                this.query.package_id = list[0].id
            }
            this.packageInfo = {}
            this.getLogAjax()
        },
        // 状态
        setStatus(val) {
            // type-> 订单状态 1 待支付 2 待发货 3 待收货 4 完成 5 已取消 6 退款关闭  7 待评价 8 待审核
            let statusTextArr = ['Unpaid', 'Processing', 'Shipped', 'Delivered', 'Cancelled', 'Refunded', 'Reviews', 'Pending'];
            let status = ['Pending', 'Unshipped', 'Shipped', 'Done', 'Canceled', 'Close', '', 'PendingAudit'];
            let type = status.indexOf(val) || 0;
            return {
                type: type + 1,
                text: statusTextArr[type]
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Track Order'),
                keywords: this.$translate('Track Order'),
                description: this.$translate('Track Order'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            if(process.client) {
                this.hasApp = this.fnApi.bridgeApp.getApp().isApp
                this.country = this.$storage.get('country') || {}
                this.hasLogin = this.$login()
            }
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
            this.params.orderId = this.query.order_id || ''
        }
    },
}
