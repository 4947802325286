
export default {
    name: 'RegisterSuccess',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            register: {
                visible: false,
                hasRegister: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        handleBtn() {
            this.register.visible = false
        },
        handleCheck() {
            this.register.visible = false
            this.$router.push({
                name: 'Mine'
            })
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.register.hasRegister = this.$storage.get('biz/hasRegister')
            this.register.visible = this.hasLogin && !!this.register.hasRegister
            if(this.hasLogin && location.href.includes('promocode')) {
                this.register.visible = false
            }
            this.$storage.remove('biz/hasRegister')
        }
    },
}
