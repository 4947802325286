
import WalletHead from './components/WalletHead'
import WalletBalance from './components/WalletBalance'
import WalletHistory from './components/WalletHistory'

export default {
    name: 'Wallet',
    components: {
        WalletHead,
        WalletBalance,
        WalletHistory
    },
    props: {},
    data() {
        return {}
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Wallet'),
                keywords: this.$translate('Wallet'),
                description: this.$translate('Wallet'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
